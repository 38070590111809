import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';

import { formatDate } from '../utility/utility';
import useRecord from '../hooks/useRecord';

function EmailDetailModal({ open, onClose, recordId }) {
    const { record, loading, error } = useRecord('email_log', recordId, open ? 1 : 0);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>Email Log #{record ? record.id : 'Loading...'}</DialogTitle>
            <DialogContent>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px' }}>
                        <CircularProgress />
                    </div>
                ) : error ? (
                    <Typography color="error">Error loading record.</Typography>
                ) : record ? (
                    <div>
                        <Typography variant="subtitle1">Basic Information:</Typography>
                        <p>Sent: {formatDate(record.created_at)}</p>
                        <p>Status: {record.status}</p>
                        <p>Email Type: {record.email_type}</p>
                        <p>Resent: {record.resent ? 'Yes' : 'No'}</p>

                        <Typography variant="subtitle1">Email Information:</Typography>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Options</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={record.options} />
                            </AccordionDetails>
                        </Accordion>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="subtitle1">Error</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <JSONPretty data={record.error} />
                            </AccordionDetails>
                        </Accordion>
                    </div>
                ) : (
                    <Typography>No data available.</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default EmailDetailModal;
